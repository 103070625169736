/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StrapiMedia } from './StrapiMedia';
import {
    StrapiMediaFromJSON,
    StrapiMediaFromJSONTyped,
    StrapiMediaToJSON,
} from './StrapiMedia';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiCompanyExtendedDetail
 */
export interface StrapiCompanyExtendedDetail {
    /**
     * 
     * @type {string}
     * @memberof StrapiCompanyExtendedDetail
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiCompanyExtendedDetail
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {StrapiMedia}
     * @memberof StrapiCompanyExtendedDetail
     */
    image?: StrapiMedia | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiCompanyExtendedDetail
     */
    youtubeUrl?: string | null;
}

/**
 * Check if a given object implements the StrapiCompanyExtendedDetail interface.
 */
export function instanceOfStrapiCompanyExtendedDetail(value: object): boolean {
    return true;
}

export function StrapiCompanyExtendedDetailFromJSON(json: any): StrapiCompanyExtendedDetail {
    return StrapiCompanyExtendedDetailFromJSONTyped(json, false);
}

export function StrapiCompanyExtendedDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiCompanyExtendedDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'shortDescription': json['short_description'] == null ? undefined : json['short_description'],
        'image': json['image'] == null ? undefined : StrapiMediaFromJSON(json['image']),
        'youtubeUrl': json['youtube_url'] == null ? undefined : json['youtube_url'],
    };
}

export function StrapiCompanyExtendedDetailToJSON(value?: StrapiCompanyExtendedDetail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'short_description': value['shortDescription'],
        'image': StrapiMediaToJSON(value['image']),
        'youtube_url': value['youtubeUrl'],
    };
}

