/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiMedia
 */
export interface StrapiMedia {
    /**
     * 
     * @type {number}
     * @memberof StrapiMedia
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiMedia
     */
    url?: string | null;
}

/**
 * Check if a given object implements the StrapiMedia interface.
 */
export function instanceOfStrapiMedia(value: object): boolean {
    return true;
}

export function StrapiMediaFromJSON(json: any): StrapiMedia {
    return StrapiMediaFromJSONTyped(json, false);
}

export function StrapiMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiMedia {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function StrapiMediaToJSON(value?: StrapiMedia | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'url': value['url'],
    };
}

