/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StrapiFileCategory } from './StrapiFileCategory';
import {
    StrapiFileCategoryFromJSON,
    StrapiFileCategoryFromJSONTyped,
    StrapiFileCategoryToJSON,
} from './StrapiFileCategory';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiIssueFileCategories
 */
export interface StrapiIssueFileCategories {
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiIssueFileCategories
     */
    amendmentToTermSheet?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiIssueFileCategories
     */
    collateralInstruments?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiIssueFileCategories
     */
    issueTermSheet?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiIssueFileCategories
     */
    otherDocuments?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiIssueFileCategories
     */
    prospectusAmendment?: StrapiFileCategory | null;
}

/**
 * Check if a given object implements the StrapiIssueFileCategories interface.
 */
export function instanceOfStrapiIssueFileCategories(value: object): boolean {
    return true;
}

export function StrapiIssueFileCategoriesFromJSON(json: any): StrapiIssueFileCategories {
    return StrapiIssueFileCategoriesFromJSONTyped(json, false);
}

export function StrapiIssueFileCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiIssueFileCategories {
    if (json == null) {
        return json;
    }
    return {
        
        'amendmentToTermSheet': json['amendment_to_term_sheet'] == null ? undefined : StrapiFileCategoryFromJSON(json['amendment_to_term_sheet']),
        'collateralInstruments': json['collateral_instruments'] == null ? undefined : StrapiFileCategoryFromJSON(json['collateral_instruments']),
        'issueTermSheet': json['issue_term_sheet'] == null ? undefined : StrapiFileCategoryFromJSON(json['issue_term_sheet']),
        'otherDocuments': json['other_documents'] == null ? undefined : StrapiFileCategoryFromJSON(json['other_documents']),
        'prospectusAmendment': json['prospectus_amendment'] == null ? undefined : StrapiFileCategoryFromJSON(json['prospectus_amendment']),
    };
}

export function StrapiIssueFileCategoriesToJSON(value?: StrapiIssueFileCategories | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amendment_to_term_sheet': StrapiFileCategoryToJSON(value['amendmentToTermSheet']),
        'collateral_instruments': StrapiFileCategoryToJSON(value['collateralInstruments']),
        'issue_term_sheet': StrapiFileCategoryToJSON(value['issueTermSheet']),
        'other_documents': StrapiFileCategoryToJSON(value['otherDocuments']),
        'prospectus_amendment': StrapiFileCategoryToJSON(value['prospectusAmendment']),
    };
}

