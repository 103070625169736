/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `A` - A
 * * `B` - B
 * * `C` - C
 * * `D` - D
 * * `E` - E
 * @export
 * @enum {string}
 */
export enum RatingEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E'
}


export function instanceOfRatingEnum(value: any): boolean {
    for (const key in RatingEnum) {
        if (Object.prototype.hasOwnProperty.call(RatingEnum, key)) {
            if (RatingEnum[key as keyof typeof RatingEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RatingEnumFromJSON(json: any): RatingEnum {
    return RatingEnumFromJSONTyped(json, false);
}

export function RatingEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatingEnum {
    return json as RatingEnum;
}

export function RatingEnumToJSON(value?: RatingEnum | null): any {
    return value as any;
}

