/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StrapiFileCategory } from './StrapiFileCategory';
import {
    StrapiFileCategoryFromJSON,
    StrapiFileCategoryFromJSONTyped,
    StrapiFileCategoryToJSON,
} from './StrapiFileCategory';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiCompanyFileCategories
 */
export interface StrapiCompanyFileCategories {
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiCompanyFileCategories
     */
    annualReport?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiCompanyFileCategories
     */
    auditReport?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiCompanyFileCategories
     */
    balanceSheet?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiCompanyFileCategories
     */
    baseProspectus?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiCompanyFileCategories
     */
    cashFlowStatement?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiCompanyFileCategories
     */
    financialStatement?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiCompanyFileCategories
     */
    incomeStatement?: StrapiFileCategory | null;
    /**
     * 
     * @type {StrapiFileCategory}
     * @memberof StrapiCompanyFileCategories
     */
    otherDocuments?: StrapiFileCategory | null;
}

/**
 * Check if a given object implements the StrapiCompanyFileCategories interface.
 */
export function instanceOfStrapiCompanyFileCategories(value: object): boolean {
    return true;
}

export function StrapiCompanyFileCategoriesFromJSON(json: any): StrapiCompanyFileCategories {
    return StrapiCompanyFileCategoriesFromJSONTyped(json, false);
}

export function StrapiCompanyFileCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiCompanyFileCategories {
    if (json == null) {
        return json;
    }
    return {
        
        'annualReport': json['annual_report'] == null ? undefined : StrapiFileCategoryFromJSON(json['annual_report']),
        'auditReport': json['audit_report'] == null ? undefined : StrapiFileCategoryFromJSON(json['audit_report']),
        'balanceSheet': json['balance_sheet'] == null ? undefined : StrapiFileCategoryFromJSON(json['balance_sheet']),
        'baseProspectus': json['base_prospectus'] == null ? undefined : StrapiFileCategoryFromJSON(json['base_prospectus']),
        'cashFlowStatement': json['cash_flow_statement'] == null ? undefined : StrapiFileCategoryFromJSON(json['cash_flow_statement']),
        'financialStatement': json['financial_statement'] == null ? undefined : StrapiFileCategoryFromJSON(json['financial_statement']),
        'incomeStatement': json['income_statement'] == null ? undefined : StrapiFileCategoryFromJSON(json['income_statement']),
        'otherDocuments': json['other_documents'] == null ? undefined : StrapiFileCategoryFromJSON(json['other_documents']),
    };
}

export function StrapiCompanyFileCategoriesToJSON(value?: StrapiCompanyFileCategories | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'annual_report': StrapiFileCategoryToJSON(value['annualReport']),
        'audit_report': StrapiFileCategoryToJSON(value['auditReport']),
        'balance_sheet': StrapiFileCategoryToJSON(value['balanceSheet']),
        'base_prospectus': StrapiFileCategoryToJSON(value['baseProspectus']),
        'cash_flow_statement': StrapiFileCategoryToJSON(value['cashFlowStatement']),
        'financial_statement': StrapiFileCategoryToJSON(value['financialStatement']),
        'income_statement': StrapiFileCategoryToJSON(value['incomeStatement']),
        'other_documents': StrapiFileCategoryToJSON(value['otherDocuments']),
    };
}

