/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiIssuerIdentification
 */
export interface StrapiIssuerIdentification {
    /**
     * 
     * @type {string}
     * @memberof StrapiIssuerIdentification
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiIssuerIdentification
     */
    legalForm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiIssuerIdentification
     */
    businessTypeByCzNace?: string | null;
}

/**
 * Check if a given object implements the StrapiIssuerIdentification interface.
 */
export function instanceOfStrapiIssuerIdentification(value: object): boolean {
    return true;
}

export function StrapiIssuerIdentificationFromJSON(json: any): StrapiIssuerIdentification {
    return StrapiIssuerIdentificationFromJSONTyped(json, false);
}

export function StrapiIssuerIdentificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiIssuerIdentification {
    if (json == null) {
        return json;
    }
    return {
        
        'startDate': json['start_date'] == null ? undefined : json['start_date'],
        'legalForm': json['legal_form'] == null ? undefined : json['legal_form'],
        'businessTypeByCzNace': json['business_type_by_cz_nace'] == null ? undefined : json['business_type_by_cz_nace'],
    };
}

export function StrapiIssuerIdentificationToJSON(value?: StrapiIssuerIdentification | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start_date': value['startDate'],
        'legal_form': value['legalForm'],
        'business_type_by_cz_nace': value['businessTypeByCzNace'],
    };
}

