/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `MONTHLY` - Monthly
 * * `QUARTERLY` - Quarterly
 * @export
 * @enum {string}
 */
export enum PeriodicityEnum {
    Monthly = 'MONTHLY',
    Quarterly = 'QUARTERLY'
}


export function instanceOfPeriodicityEnum(value: any): boolean {
    for (const key in PeriodicityEnum) {
        if (Object.prototype.hasOwnProperty.call(PeriodicityEnum, key)) {
            if (PeriodicityEnum[key as keyof typeof PeriodicityEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PeriodicityEnumFromJSON(json: any): PeriodicityEnum {
    return PeriodicityEnumFromJSONTyped(json, false);
}

export function PeriodicityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodicityEnum {
    return json as PeriodicityEnum;
}

export function PeriodicityEnumToJSON(value?: PeriodicityEnum | null): any {
    return value as any;
}

