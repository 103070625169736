/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiFileList
 */
export interface StrapiFileList {
    /**
     * 
     * @type {string}
     * @memberof StrapiFileList
     */
    customFilename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiFileList
     */
    filename?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiFileList
     */
    year?: number | null;
}

/**
 * Check if a given object implements the StrapiFileList interface.
 */
export function instanceOfStrapiFileList(value: object): boolean {
    return true;
}

export function StrapiFileListFromJSON(json: any): StrapiFileList {
    return StrapiFileListFromJSONTyped(json, false);
}

export function StrapiFileListFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiFileList {
    if (json == null) {
        return json;
    }
    return {
        
        'customFilename': json['custom_filename'] == null ? undefined : json['custom_filename'],
        'filename': json['filename'] == null ? undefined : json['filename'],
        'year': json['year'] == null ? undefined : json['year'],
    };
}

export function StrapiFileListToJSON(value?: StrapiFileList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'custom_filename': value['customFilename'],
        'filename': value['filename'],
        'year': value['year'],
    };
}

