/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `30E/360` - Thirty E 360
 * @export
 * @enum {string}
 */
export enum InterestCalculationMethodEnum {
    _30E360 = '30E/360'
}


export function instanceOfInterestCalculationMethodEnum(value: any): boolean {
    for (const key in InterestCalculationMethodEnum) {
        if (Object.prototype.hasOwnProperty.call(InterestCalculationMethodEnum, key)) {
            if (InterestCalculationMethodEnum[key as keyof typeof InterestCalculationMethodEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function InterestCalculationMethodEnumFromJSON(json: any): InterestCalculationMethodEnum {
    return InterestCalculationMethodEnumFromJSONTyped(json, false);
}

export function InterestCalculationMethodEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterestCalculationMethodEnum {
    return json as InterestCalculationMethodEnum;
}

export function InterestCalculationMethodEnumToJSON(value?: InterestCalculationMethodEnum | null): any {
    return value as any;
}

