/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StrapiIssuerFinancialStatements } from './StrapiIssuerFinancialStatements';
import {
    StrapiIssuerFinancialStatementsFromJSON,
    StrapiIssuerFinancialStatementsFromJSONTyped,
    StrapiIssuerFinancialStatementsToJSON,
} from './StrapiIssuerFinancialStatements';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiIssuerFinancialResults
 */
export interface StrapiIssuerFinancialResults {
    /**
     * 
     * @type {string}
     * @memberof StrapiIssuerFinancialResults
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiIssuerFinancialResults
     */
    description?: string | null;
    /**
     * 
     * @type {Array<StrapiIssuerFinancialStatements>}
     * @memberof StrapiIssuerFinancialResults
     */
    statements?: Array<StrapiIssuerFinancialStatements> | null;
}

/**
 * Check if a given object implements the StrapiIssuerFinancialResults interface.
 */
export function instanceOfStrapiIssuerFinancialResults(value: object): boolean {
    return true;
}

export function StrapiIssuerFinancialResultsFromJSON(json: any): StrapiIssuerFinancialResults {
    return StrapiIssuerFinancialResultsFromJSONTyped(json, false);
}

export function StrapiIssuerFinancialResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiIssuerFinancialResults {
    if (json == null) {
        return json;
    }
    return {
        
        'shortDescription': json['short_description'] == null ? undefined : json['short_description'],
        'description': json['description'] == null ? undefined : json['description'],
        'statements': json['statements'] == null ? undefined : ((json['statements'] as Array<any>).map(StrapiIssuerFinancialStatementsFromJSON)),
    };
}

export function StrapiIssuerFinancialResultsToJSON(value?: StrapiIssuerFinancialResults | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'short_description': value['shortDescription'],
        'description': value['description'],
        'statements': value['statements'] == null ? undefined : ((value['statements'] as Array<any>).map(StrapiIssuerFinancialStatementsToJSON)),
    };
}

