/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StrapiFileList } from './StrapiFileList';
import {
    StrapiFileListFromJSON,
    StrapiFileListFromJSONTyped,
    StrapiFileListToJSON,
} from './StrapiFileList';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiCollateral
 */
export interface StrapiCollateral {
    /**
     * 
     * @type {number}
     * @memberof StrapiCollateral
     */
    amount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiCollateral
     */
    description?: string | null;
    /**
     * 
     * @type {Array<StrapiFileList>}
     * @memberof StrapiCollateral
     */
    files?: Array<StrapiFileList> | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiCollateral
     */
    type?: string | null;
}

/**
 * Check if a given object implements the StrapiCollateral interface.
 */
export function instanceOfStrapiCollateral(value: object): boolean {
    return true;
}

export function StrapiCollateralFromJSON(json: any): StrapiCollateral {
    return StrapiCollateralFromJSONTyped(json, false);
}

export function StrapiCollateralFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiCollateral {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'] == null ? undefined : json['amount'],
        'description': json['description'] == null ? undefined : json['description'],
        'files': json['files'] == null ? undefined : ((json['files'] as Array<any>).map(StrapiFileListFromJSON)),
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function StrapiCollateralToJSON(value?: StrapiCollateral | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'description': value['description'],
        'files': value['files'] == null ? undefined : ((value['files'] as Array<any>).map(StrapiFileListToJSON)),
        'type': value['type'],
    };
}

