/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StrapiMedia } from './StrapiMedia';
import {
    StrapiMediaFromJSON,
    StrapiMediaFromJSONTyped,
    StrapiMediaToJSON,
} from './StrapiMedia';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiHeroSection
 */
export interface StrapiHeroSection {
    /**
     * 
     * @type {number}
     * @memberof StrapiHeroSection
     */
    id?: number | null;
    /**
     * 
     * @type {StrapiMedia}
     * @memberof StrapiHeroSection
     */
    image?: StrapiMedia | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiHeroSection
     */
    youtubeUrl?: string | null;
    /**
     * 
     * @type {StrapiMedia}
     * @memberof StrapiHeroSection
     */
    video?: StrapiMedia | null;
}

/**
 * Check if a given object implements the StrapiHeroSection interface.
 */
export function instanceOfStrapiHeroSection(value: object): boolean {
    return true;
}

export function StrapiHeroSectionFromJSON(json: any): StrapiHeroSection {
    return StrapiHeroSectionFromJSONTyped(json, false);
}

export function StrapiHeroSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiHeroSection {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'image': json['image'] == null ? undefined : StrapiMediaFromJSON(json['image']),
        'youtubeUrl': json['youtube_url'] == null ? undefined : json['youtube_url'],
        'video': json['video'] == null ? undefined : StrapiMediaFromJSON(json['video']),
    };
}

export function StrapiHeroSectionToJSON(value?: StrapiHeroSection | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'image': StrapiMediaToJSON(value['image']),
        'youtube_url': value['youtubeUrl'],
        'video': StrapiMediaToJSON(value['video']),
    };
}

