/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PeriodicityEnum } from './PeriodicityEnum';
import {
    PeriodicityEnumFromJSON,
    PeriodicityEnumFromJSONTyped,
    PeriodicityEnumToJSON,
} from './PeriodicityEnum';
import type { RatingEnum } from './RatingEnum';
import {
    RatingEnumFromJSON,
    RatingEnumFromJSONTyped,
    RatingEnumToJSON,
} from './RatingEnum';
import type { StrapiCollateral } from './StrapiCollateral';
import {
    StrapiCollateralFromJSON,
    StrapiCollateralFromJSONTyped,
    StrapiCollateralToJSON,
} from './StrapiCollateral';
import type { StrapiHeroSection } from './StrapiHeroSection';
import {
    StrapiHeroSectionFromJSON,
    StrapiHeroSectionFromJSONTyped,
    StrapiHeroSectionToJSON,
} from './StrapiHeroSection';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface IssueList
 */
export interface IssueList {
    /**
     * 
     * @type {number}
     * @memberof IssueList
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof IssueList
     */
    interestRate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IssueList
     */
    investedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof IssueList
     */
    investorsCount: number;
    /**
     * 
     * @type {Date}
     * @memberof IssueList
     */
    maturityDate: Date;
    /**
     * 
     * @type {string}
     * @memberof IssueList
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof IssueList
     */
    nominalValue: number;
    /**
     * 
     * @type {PeriodicityEnum}
     * @memberof IssueList
     */
    periodicity: PeriodicityEnum;
    /**
     * 
     * @type {RatingEnum}
     * @memberof IssueList
     */
    rating?: RatingEnum | null;
    /**
     * 
     * @type {number}
     * @memberof IssueList
     */
    remainingAmount: number;
    /**
     * 
     * @type {Array<StrapiCollateral>}
     * @memberof IssueList
     */
    readonly collaterals: Array<StrapiCollateral>;
    /**
     * 
     * @type {StrapiHeroSection}
     * @memberof IssueList
     */
    readonly heroSection: StrapiHeroSection | null;
    /**
     * 
     * @type {string}
     * @memberof IssueList
     */
    readonly projectName: string | null;
    /**
     * 
     * @type {string}
     * @memberof IssueList
     */
    readonly tagline: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IssueList
     */
    readonly tags: Array<string>;
}



/**
 * Check if a given object implements the IssueList interface.
 */
export function instanceOfIssueList(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('investedAmount' in value)) return false;
    if (!('investorsCount' in value)) return false;
    if (!('maturityDate' in value)) return false;
    if (!('name' in value)) return false;
    if (!('nominalValue' in value)) return false;
    if (!('periodicity' in value)) return false;
    if (!('remainingAmount' in value)) return false;
    if (!('collaterals' in value)) return false;
    if (!('heroSection' in value)) return false;
    if (!('projectName' in value)) return false;
    if (!('tagline' in value)) return false;
    if (!('tags' in value)) return false;
    return true;
}

export function IssueListFromJSON(json: any): IssueList {
    return IssueListFromJSONTyped(json, false);
}

export function IssueListFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'interestRate': json['interest_rate'] == null ? undefined : json['interest_rate'],
        'investedAmount': json['invested_amount'],
        'investorsCount': json['investors_count'],
        'maturityDate': (dayjs.tz(json['maturity_date'], 'YYYY-MM-DD', dayjs.tz.guess()).toDate()),
        'name': json['name'],
        'nominalValue': json['nominal_value'],
        'periodicity': PeriodicityEnumFromJSON(json['periodicity']),
        'rating': json['rating'] == null ? undefined : RatingEnumFromJSON(json['rating']),
        'remainingAmount': json['remaining_amount'],
        'collaterals': ((json['collaterals'] as Array<any>).map(StrapiCollateralFromJSON)),
        'heroSection': StrapiHeroSectionFromJSON(json['hero_section']),
        'projectName': json['project_name'],
        'tagline': json['tagline'],
        'tags': json['tags'],
    };
}

export function IssueListToJSON(value?: IssueList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interest_rate': value['interestRate'],
        'invested_amount': value['investedAmount'],
        'investors_count': value['investorsCount'],
        'maturity_date': (dayjs(value['maturityDate']).format('YYYY-MM-DD')),
        'name': value['name'],
        'nominal_value': value['nominalValue'],
        'periodicity': PeriodicityEnumToJSON(value['periodicity']),
        'rating': RatingEnumToJSON(value['rating']),
        'remaining_amount': value['remainingAmount'],
    };
}

