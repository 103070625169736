/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StrapiCompanyExtendedDetail } from './StrapiCompanyExtendedDetail';
import {
    StrapiCompanyExtendedDetailFromJSON,
    StrapiCompanyExtendedDetailFromJSONTyped,
    StrapiCompanyExtendedDetailToJSON,
} from './StrapiCompanyExtendedDetail';
import type { StrapiCompanyFileCategories } from './StrapiCompanyFileCategories';
import {
    StrapiCompanyFileCategoriesFromJSON,
    StrapiCompanyFileCategoriesFromJSONTyped,
    StrapiCompanyFileCategoriesToJSON,
} from './StrapiCompanyFileCategories';
import type { StrapiCompanyKeyPerson } from './StrapiCompanyKeyPerson';
import {
    StrapiCompanyKeyPersonFromJSON,
    StrapiCompanyKeyPersonFromJSONTyped,
    StrapiCompanyKeyPersonToJSON,
} from './StrapiCompanyKeyPerson';
import type { StrapiIssuerFinancialResults } from './StrapiIssuerFinancialResults';
import {
    StrapiIssuerFinancialResultsFromJSON,
    StrapiIssuerFinancialResultsFromJSONTyped,
    StrapiIssuerFinancialResultsToJSON,
} from './StrapiIssuerFinancialResults';
import type { StrapiIssuerIdentification } from './StrapiIssuerIdentification';
import {
    StrapiIssuerIdentificationFromJSON,
    StrapiIssuerIdentificationFromJSONTyped,
    StrapiIssuerIdentificationToJSON,
} from './StrapiIssuerIdentification';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiIssuer
 */
export interface StrapiIssuer {
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuer
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof StrapiIssuer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StrapiIssuer
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof StrapiIssuer
     */
    readonly companyWebsiteUrl: string;
    /**
     * 
     * @type {StrapiIssuerIdentification}
     * @memberof StrapiIssuer
     */
    identification?: StrapiIssuerIdentification | null;
    /**
     * 
     * @type {StrapiIssuerFinancialResults}
     * @memberof StrapiIssuer
     */
    financialResults?: StrapiIssuerFinancialResults | null;
    /**
     * 
     * @type {StrapiCompanyExtendedDetail}
     * @memberof StrapiIssuer
     */
    extendedDetail?: StrapiCompanyExtendedDetail | null;
    /**
     * 
     * @type {StrapiCompanyFileCategories}
     * @memberof StrapiIssuer
     */
    fileCategories?: StrapiCompanyFileCategories | null;
    /**
     * 
     * @type {Array<StrapiCompanyKeyPerson>}
     * @memberof StrapiIssuer
     */
    readonly keyPersons: Array<StrapiCompanyKeyPerson>;
}

/**
 * Check if a given object implements the StrapiIssuer interface.
 */
export function instanceOfStrapiIssuer(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('companyWebsiteUrl' in value)) return false;
    if (!('keyPersons' in value)) return false;
    return true;
}

export function StrapiIssuerFromJSON(json: any): StrapiIssuer {
    return StrapiIssuerFromJSONTyped(json, false);
}

export function StrapiIssuerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiIssuer {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'companyId': json['company_id'],
        'companyWebsiteUrl': json['company_website_url'],
        'identification': json['identification'] == null ? undefined : StrapiIssuerIdentificationFromJSON(json['identification']),
        'financialResults': json['financial_results'] == null ? undefined : StrapiIssuerFinancialResultsFromJSON(json['financial_results']),
        'extendedDetail': json['extended_detail'] == null ? undefined : StrapiCompanyExtendedDetailFromJSON(json['extended_detail']),
        'fileCategories': json['file_categories'] == null ? undefined : StrapiCompanyFileCategoriesFromJSON(json['file_categories']),
        'keyPersons': ((json['key_persons'] as Array<any>).map(StrapiCompanyKeyPersonFromJSON)),
    };
}

export function StrapiIssuerToJSON(value?: StrapiIssuer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'company_id': value['companyId'],
        'identification': StrapiIssuerIdentificationToJSON(value['identification']),
        'financial_results': StrapiIssuerFinancialResultsToJSON(value['financialResults']),
        'extended_detail': StrapiCompanyExtendedDetailToJSON(value['extendedDetail']),
        'file_categories': StrapiCompanyFileCategoriesToJSON(value['fileCategories']),
    };
}

