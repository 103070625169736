/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface InvestResponse
 */
export interface InvestResponse {
    /**
     * 
     * @type {string}
     * @memberof InvestResponse
     */
    investedAmount: string;
    /**
     * 
     * @type {string}
     * @memberof InvestResponse
     */
    investedAmountWithReturnNet: string;
    /**
     * 
     * @type {string}
     * @memberof InvestResponse
     */
    returnNet: string;
    /**
     * 
     * @type {string}
     * @memberof InvestResponse
     */
    returnNetPerPeriod: string;
    /**
     * 
     * @type {string}
     * @memberof InvestResponse
     */
    returnPercentage: string;
}

/**
 * Check if a given object implements the InvestResponse interface.
 */
export function instanceOfInvestResponse(value: object): boolean {
    if (!('investedAmount' in value)) return false;
    if (!('investedAmountWithReturnNet' in value)) return false;
    if (!('returnNet' in value)) return false;
    if (!('returnNetPerPeriod' in value)) return false;
    if (!('returnPercentage' in value)) return false;
    return true;
}

export function InvestResponseFromJSON(json: any): InvestResponse {
    return InvestResponseFromJSONTyped(json, false);
}

export function InvestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'investedAmount': json['invested_amount'],
        'investedAmountWithReturnNet': json['invested_amount_with_return_net'],
        'returnNet': json['return_net'],
        'returnNetPerPeriod': json['return_net_per_period'],
        'returnPercentage': json['return_percentage'],
    };
}

export function InvestResponseToJSON(value?: InvestResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invested_amount': value['investedAmount'],
        'invested_amount_with_return_net': value['investedAmountWithReturnNet'],
        'return_net': value['returnNet'],
        'return_net_per_period': value['returnNetPerPeriod'],
        'return_percentage': value['returnPercentage'],
    };
}

