/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiIssueReasonsToInvest
 */
export interface StrapiIssueReasonsToInvest {
    /**
     * 
     * @type {string}
     * @memberof StrapiIssueReasonsToInvest
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiIssueReasonsToInvest
     */
    iconUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof StrapiIssueReasonsToInvest
     */
    title: string | null;
}

/**
 * Check if a given object implements the StrapiIssueReasonsToInvest interface.
 */
export function instanceOfStrapiIssueReasonsToInvest(value: object): boolean {
    if (!('description' in value)) return false;
    if (!('iconUrl' in value)) return false;
    if (!('title' in value)) return false;
    return true;
}

export function StrapiIssueReasonsToInvestFromJSON(json: any): StrapiIssueReasonsToInvest {
    return StrapiIssueReasonsToInvestFromJSONTyped(json, false);
}

export function StrapiIssueReasonsToInvestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiIssueReasonsToInvest {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'],
        'iconUrl': json['icon_url'],
        'title': json['title'],
    };
}

export function StrapiIssueReasonsToInvestToJSON(value?: StrapiIssueReasonsToInvest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'icon_url': value['iconUrl'],
        'title': value['title'],
    };
}

