/* tslint:disable */
/* eslint-disable */
/**
 * Brew API
 * Microservice backend for brew management
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 
 * @export
 * @interface StrapiIssuerFinancialStatements
 */
export interface StrapiIssuerFinancialStatements {
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    longtermAssets?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    currentAssets?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    liabilities?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    equity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    revenues?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    expenses?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    profit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    foreignResources?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    assets?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    debt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    depreciation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    revenueFromSalesOfProducts?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    revenueFromSalesOfGoods?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    incomeTradingBeforeTax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StrapiIssuerFinancialStatements
     */
    expenseInterest?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof StrapiIssuerFinancialStatements
     */
    availableAnnualReport?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StrapiIssuerFinancialStatements
     */
    availableCashFlow?: boolean | null;
}

/**
 * Check if a given object implements the StrapiIssuerFinancialStatements interface.
 */
export function instanceOfStrapiIssuerFinancialStatements(value: object): boolean {
    return true;
}

export function StrapiIssuerFinancialStatementsFromJSON(json: any): StrapiIssuerFinancialStatements {
    return StrapiIssuerFinancialStatementsFromJSONTyped(json, false);
}

export function StrapiIssuerFinancialStatementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StrapiIssuerFinancialStatements {
    if (json == null) {
        return json;
    }
    return {
        
        'year': json['year'] == null ? undefined : json['year'],
        'longtermAssets': json['longterm_assets'] == null ? undefined : json['longterm_assets'],
        'currentAssets': json['current_assets'] == null ? undefined : json['current_assets'],
        'liabilities': json['liabilities'] == null ? undefined : json['liabilities'],
        'equity': json['equity'] == null ? undefined : json['equity'],
        'revenues': json['revenues'] == null ? undefined : json['revenues'],
        'expenses': json['expenses'] == null ? undefined : json['expenses'],
        'profit': json['profit'] == null ? undefined : json['profit'],
        'foreignResources': json['foreign_resources'] == null ? undefined : json['foreign_resources'],
        'assets': json['assets'] == null ? undefined : json['assets'],
        'debt': json['debt'] == null ? undefined : json['debt'],
        'depreciation': json['depreciation'] == null ? undefined : json['depreciation'],
        'revenueFromSalesOfProducts': json['revenue_from_sales_of_products'] == null ? undefined : json['revenue_from_sales_of_products'],
        'revenueFromSalesOfGoods': json['revenue_from_sales_of_goods'] == null ? undefined : json['revenue_from_sales_of_goods'],
        'incomeTradingBeforeTax': json['income_trading_before_tax'] == null ? undefined : json['income_trading_before_tax'],
        'expenseInterest': json['expense_interest'] == null ? undefined : json['expense_interest'],
        'availableAnnualReport': json['available_annual_report'] == null ? undefined : json['available_annual_report'],
        'availableCashFlow': json['available_cash_flow'] == null ? undefined : json['available_cash_flow'],
    };
}

export function StrapiIssuerFinancialStatementsToJSON(value?: StrapiIssuerFinancialStatements | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'year': value['year'],
        'longterm_assets': value['longtermAssets'],
        'current_assets': value['currentAssets'],
        'liabilities': value['liabilities'],
        'equity': value['equity'],
        'revenues': value['revenues'],
        'expenses': value['expenses'],
        'profit': value['profit'],
        'foreign_resources': value['foreignResources'],
        'assets': value['assets'],
        'debt': value['debt'],
        'depreciation': value['depreciation'],
        'revenue_from_sales_of_products': value['revenueFromSalesOfProducts'],
        'revenue_from_sales_of_goods': value['revenueFromSalesOfGoods'],
        'income_trading_before_tax': value['incomeTradingBeforeTax'],
        'expense_interest': value['expenseInterest'],
        'available_annual_report': value['availableAnnualReport'],
        'available_cash_flow': value['availableCashFlow'],
    };
}

